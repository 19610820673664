import { Controller } from "stimulus";
import Tribute from "tributejs";

export default class extends Controller {
  static values = { variables: Object };

  connect() {
    this.variables = this.hasVariablesValue
      ? this.variablesValue
      : this.getVariables();

    this.setUpTribute();
  }

  setUpTribute() {
    this.tribute = new Tribute(this.tributeOptions);
    this.tribute.attach(this.element);
  }

  get tributeOptions() {
    return {
      values: Object.keys(this.variables).map((key) => {
        return { key: key, value: this.variables[key] };
      }),
      trigger: "$",
      requireLeadingSpace: true,
      replaceTextSuffix: " ",
      spaceSelectsMatch: false,
      selectClass: "bg-gray-50",
      searchOpts: {
        pre: "<span class='bg-yellow-200'>",
        post: "</span>",
      },
      selectTemplate: (item) => {
        return item ? `$${item.original.key}` : null;
      },
      noMatchTemplate: () => {
        return;
      },
      containerClass:
        "overflow-hidden border border-gray-100 cursor-pointer rounded-xl bg-white text-sm max-w-64 shadow-pretty z-50 project-variable-dropdown overflow-hidden",
      menuItemTemplate: (item) => {
        return `<div class="flex flex-col justify-between hover:bg-gray-50 focus:bg-gray-50 focus:outline-none py-2 pl-3 pr-12">
        <p class="whitespace-nowrap shrink-0">$${item.string}</p>
        <p class="text-gray-300 whitespace-pre-wrap flex-1 text-sm">${item.original.value}</p>
      </div>`;
      },
    };
  }

  getVariables() {
    const metaTag = document.querySelector("meta[name=project-variables]");

    return metaTag ? JSON.parse(metaTag.content) : this.defaultVariables;
  }

  get defaultVariables() {
    return {
      PROJECT_NAME: "",
      CLIENT_NAME: "",
      ADVISOR_NAME: "",
    };
  }
}
