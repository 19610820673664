import { Controller } from "stimulus";
import { FetchRequest } from "@rails/request.js";

export default class extends Controller {
  static targets = ["input", "loading"];
  static values = { url: String };

  initialize() {
    this.handleKeydown = this._keydown.bind(this);
  }

  connect() {
    if (this.hasInputTarget) {
      this.inputTarget.addEventListener("keydown", this.handleKeydown);
    }
  }

  disconnect() {
    if (this.hasInputTarget) {
      this.inputTarget.removeEventListener("keydown", this.handleKeydown);
    }
  }

  // "cmd + control + p"
  _keydown(event) {
    if (!event.metaKey) return;
    if (!event.ctrlKey) return;
    if (event.key !== "p") return;

    // Prevent default
    event.preventDefault();

    // Run proofread logic
    this.proofread();
  }

  proofread() {
    if (this.hasNoContent) return;

    // Display loading state and disable element
    this.loadingTarget.innerHTML = this.loadingHTML;
    this.inputTarget.setAttribute("contenteditable", false);
    this.element.blur();

    // Send request to the proofreads endpoint
    const request = new FetchRequest("GET", this.urlValue);
    request.perform();
  }

  get hasNoContent() {
    let result = false;
    if (this.inputTarget.tagName.toLowerCase() === "textarea") {
      result = this.inputTarget.value.trim() === "";
    } else {
      result = this.inputTarget.innerText.trim() === "";
    }

    return result;
  }

  get loadingHTML() {
    const classes = "text-gray-400 animate-pulse text-xs font-medium";
    return `<div class="${classes}">Proofreading...</div>`;
  }
}
