export default class ContentElementPersister {
  constructor(contentElement) {
    this.contentElement = contentElement;
    this.id = contentElement.dataset.contentElementId;
  }

  save() {
    fetch(`/content_elements/${this.id}/saves`, {
      method: "PATCH",
      headers: this.headers,
      body: JSON.stringify({ content: this.input.content }),
    });
  }

  split() {
    const input = this.input;
    const beforeCursor = input.contentBeforeCursor;
    const afterCursor = input.contentAfterCursor;
    const url = `/content_elements/${this.id}/splits`;
    input.blur();

    fetch(url, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify({
        before_cursor: beforeCursor,
        after_cursor: afterCursor,
      }),
    });
  }

  mergePrevious() {
    fetch(`/content_elements/${this.id}/merges`, {
      method: "PATCH",
      headers: this.headers,
      body: JSON.stringify({
        direction: "previous",
        content: this.input.content,
      }),
    });
  }

  mergeNext() {
    fetch(`/content_elements/${this.id}/merges`, {
      method: "PATCH",
      headers: this.headers,
      body: JSON.stringify({
        direction: "next",
        content: this.input.content,
      }),
    });
  }

  indent() {
    fetch(`/content_elements/${this.id}/indentations`, {
      method: "PATCH",
      headers: this.headers,
      body: JSON.stringify({
        content: this.input.content,
        direction: "indent",
      }),
    });
  }

  unindent() {
    fetch(`/content_elements/${this.id}/indentations`, {
      method: "PATCH",
      headers: this.headers,
      body: JSON.stringify({
        content: this.input.content,
        direction: "unindent",
      }),
    });
  }

  destroy() {
    fetch(`/content_elements/${this.id}/deletions`, {
      method: "DELETE",
      headers: this.headers,
    });
  }

  get input() {
    return this.contentElement.input;
  }

  get headers() {
    return {
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
      "Content-Type": "application/json",
    };
  }
}
