import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    description: String,
    title: String,
    search: String,
    url: String,
  };

  static targets = ["trigger", "thinking"];

  async generate() {
    // Make sure we have a title value to work with
    let title = this._getTitle();
    if (!title) return alert("Enter a footnote title to continue...");

    // Capitalize the first letter of the title
    title = title.charAt(0).toUpperCase() + title.slice(1);

    // Set title value and show thinking state
    this.title.value = title;
    this.triggerTarget.classList.add("hidden");
    this.thinkingTarget.classList.remove("hidden");

    // Fetch the content from the server
    const response = await fetch(this.urlValue, {
      method: "POST",
      body: JSON.stringify({ title: title }),
      headers: this.headers,
    });

    // Set the description value
    const body = await response.json();
    const content = body.content;
    this.description.value = content;
    this.description.dispatchEvent(new Event("input", { bubbles: true }));

    // Hide thinking state and show trigger
    this.thinkingTarget.classList.add("hidden");
    this.triggerTarget.classList.remove("hidden");
  }

  _getTitle() {
    const titleValue = this.title.value;
    if (titleValue.length > 0) return titleValue;

    const searchValue = this.search.value;
    if (searchValue.length > 0) return searchValue;

    return null;
  }

  get title() {
    return document.getElementById(this.titleValue);
  }

  get search() {
    return document.getElementById(this.searchValue);
  }

  get description() {
    return document.getElementById(this.descriptionValue);
  }

  get headers() {
    return {
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content,
    };
  }
}
