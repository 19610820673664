import { Controller } from "stimulus";

export default class extends Controller {
  static values = { path: String };

  async connect() {
    const response = await fetch(this.pathValue, { headers: this.headers });
    const html = await response.text();
    Turbo.renderStreamMessage(html);
    this.element.remove();
  }

  get headers() {
    return {
      Accept: "text/vnd.turbo-stream.html",
      "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content,
    };
  }
}
